<template>
  <div class="pb-16">

    <h1 class="mb-5">Ayuda</h1>

    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pb-3 mb-5">
          <v-list class="pt-0">
            <v-subheader class="grey lighten-3 mb-3">Temas</v-subheader>
            <v-list-item v-for=" (item, index) in topics " :key="index" color="min-height">
              <v-list-item-content class="px-0">
                <v-list-item-title>
                  <a @click="$vuetify.goTo(item.tag, { offset: 0 })">{{ item.name }}</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Incidencias
          </v-card-title>
          <v-card-text>
            <p>En caso de tener sugerencias, aparición de errores o mal funcionamiento de la plataforma podeís contactarnos enviando un correo electrónico a <a href="mailto:timii@pohlbros.com">timii@pohlbros.com</a>.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

      <!-- <v-col id="objetivo" md="8">
        <v-card>
          <v-card-title>
            Objetivo
          </v-card-title>
          <v-card-text>
          </v-card-text>
        </v-card>
      </v-col> -->

      <v-row>

      <v-col id="objetivo" md="8">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Objetivo
          </v-card-title>
          <v-card-text>
            <p><span class="font-weight-medium black--text">Crear una base de datos dinámica</span> (que siga expandiéndose y actualizándose) para obtener una visión global sobre las instituciones que existen a nivel nacional e internacional que estén relacionados con la <span class="font-weight-medium black--text">medicina integrativa</span> y aprovechar dicha información para ampliar y actualizar con cada revisión el documento ESTADO ACTUAL DE LA INVESTIGACIÓN Y EVIDENCIA CIENTÍFICA EN ACUPUNTURA/MTC del Comité Científico Observatorio de Acupuntura y Medicina Tradicional China y la Fundación Terapias Naturales.</p>
            <p class="font-weight-medium black--text">De momento el foco está establecido en instituciones relacionadas con la:</p>
            <ul>
              <li>Medicina Tradicional China</li>
              <li>Acupuntura</li>
            </ul>
            <br>
            <p class="font-weight-medium black--text">Tipos de instituciones que se registran de momento:</p>
            <ul>
              <li>Centros de investigación</li>
              <li>Sociedades científicas</li>
              <li>Centros de enseñanza</li>
              <li>Centros de salud / Hospitales</li>
              <li>Fundaciones</li>
              <li>Colaboradores de la WHO</li>
              <li>Otros</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col id="instrucciones-tags" md="8">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Instrucciones para el uso tags
          </v-card-title>
          <v-card-text>
            <p class="font-weight-medium black--text">¿Qué son tags o etiquetas?</p>
            <p class="font-weight-medium black--text">Una etiqueta o tag es una palabra clave asignada a un dato almacenado en un repositorio. Las etiquetas
              son en consecuencia un tipo de metadato, pues proporcionan información que describe el dato y que facilita
              su recuperación.</p>
            <p>La diferencia entre las etiquetas y las palabras clave tradicionales es que las etiquetas son elegidas de
              manera informal y personal por los usuarios del repositorio. A diferencia de otros sistemas de
              clasificación, en los sistemas basados en etiquetas no es necesario que exista un esquema de clasificación
              previo (por ejemplo, un tesauro) como base para la clasificación. En los sitios web que permiten etiquetar
              sus datos, la colección de etiquetas se llama folksonomía.</p>
            <p>El empleo de etiquetas, en comparación con otros sistemas de clasificación, es más flexible y resulta
              especialmente sencillo. Así, permite reclasificar un dato con solo modificar sus etiquetas. Todas las
              conexiones entre el dato reclasificado y otros datos almacenados se actualizan automáticamente sin
              necesidad de intervención de la persona que ha realizado la tarea. Tampoco es necesario cambiar el dato de
              categoría dentro de una compleja jerarquía de categorías tal y como ocurre en los sistemas tradicionales.
            </p>
            <p>Nube de palabras creada con el texto de este artículo.</p>
            <p>El empleo de etiquetas permite crear una forma muy popular de acceso a los datos que consiste en
              mostrar una nube de palabras. En ella se representan visualmente las etiquetas más utilizadas en el
              repositorio, mostrando con mayor tamaño aquellas etiquetas que aparecen con más frecuencia.</p>
            <p>Sin embargo, a las ventajas anteriores se contraponen algunas desventajas:</p>
            <ul>
              <li><span class="font-weight-medium black--text">Las etiquetas no tienen un significado semántico, único y explícito.</span> Así por ejemplo, la etiqueta
                "rosa", además de al color rosa, puede hacer referencia a la flor del mismo nombre. Esta carencia de
                distinción semántica inequívoca puede conducir a conexiones inadecuadas entre datos sin relación por
                culpa de un etiquetado homónimo.</li>
              <li><span class="font-weight-medium black--text">La elección de la "palabra de la etiqueta" es completamente personal, por lo que puede darse un
                etiquetado sinónimo que obligue a realizar múltiples búsquedas para encontrar todos los datos
                relevantes para una cierta consulta.</span> Por ejemplo, las etiquetas "medicamento" y "fármaco" pueden
                usarse indistintamente para describir un mismo recurso. Los "etiquetadores" deberán juzgar, según el
                número de conexiones y las significantes alternativas, qué posibles conexiones entre los datos son
                válidas para sus intereses.</li>
              <li><span class="font-weight-medium black--text">Al ser libre la elección, los usuarios pueden introducir en el sistema etiquetas sin sentido o que
                solo tengan sentido para ellos mismos. Estas etiquetas "polucionan" en cierto modo la folksonomía,
                creando un gran número de etiquetas útiles solo para unos pocos.</span> Un usuario que utilizara su
                identificador (por ejemplo "juan1969") para etiquetar aquellos recursos de su interés sería un ejemplo
                de lo anterior.</li>
            </ul>
            <br>
            <p class="font-weight-medium red--text">Como el uso de tags tiene como objetivo la identificación y búsqueda de información recomendamos un uso responsable. </p>
            <p class="font-weight-medium black--text">Ejemplos de tags:</p>
            <ul>
              <li>Acupuntura </li>
              <li>MTC</li>
              <li>Medicina Tradicional China</li>
              <li>…</li>
            </ul>
          </v-card-text>
        </v-card>        
      </v-col>

      <v-col id="insercion-actualizacion-datos" cols="12" md="8">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Indicaciones para la inserción y actualización de datos
          </v-card-title>
          <v-card-text>
            <p>Es importante que la los datos aportados sean lo más exactos posibles. Así la búsqueda y estructuración de los datos será más sencilla. Antes de insertar nuevos datos es recomendable comprobar si ya existe en la base de datos. <span class="font-weight-medium black--text">El sistema no avisa de datos duplicados</span>.</p>
            <p>Los campos de país, estado, ciudad son campos en los que, mientras se escribe se recomiendan datos ya añadidos previamente a la base de datos y se pueden añadir datos nuevos.</p>
          
            <v-img
              lazy-src="@/assets/img/00.jpg"
              src="@/assets/img/00.jpg"
              class="mb-5"
              max-height="150"
              max-width="100%"   
              contain
            ></v-img>

            <p><span class="font-weight-medium black--text">Es muy importante no duplicar datos</span> y escribir cada dato de forma correcta (ortografía e idioma).</p>

            <v-img
              lazy-src="@/assets/img/01.jpg"
              src="@/assets/img/01.jpg"
              class="mb-5"
              max-height="330"
              max-width="100%"   
              contain
            ></v-img>

            <p class="font-weight-medium black--text">Ejemplo:</p>
            <ul>
              <li>Los datos marcados en rojo son el mismo término, pero en idiomas distintos.</li>
              <li>El dato marcado en azul es erróneo, no se trata de un país.</li>
              <li>Lo mismo vale para los tags.</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col id="notificaciones" md="8">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Uso de notificaciones
          </v-card-title>
          <v-card-text>
            <p>Cada institucion esté aprobada o no, tiene unas notificaciones asociadas. Éstas <span class="font-weight-medium black--text">sirven para comentar, proponer o avisar sobre una institución</span>. Recomendamos usar las notificaciones <span class="font-weight-medium black--text">solo para aportar información relevante</span> acerca de una institución.</p>
            <p class="font-weight-medium black--text">Como por ejemplo:</p>
            <ul>
              <li>Pedir la actualización de datos obsoletos</li>
              <li>Avisar de su cierre</li>
              <li>Comentar de cuando se ha contactado</li>
              <li>Proponer su borrado por un motivo específico</li>
              <li>etc.</li>
            </ul>
            <p class="font-weight-medium black--text mt-6">¿Como consultar las notificaciones?</p>
            <v-row>
              <v-col class="text-center">
                <h3>Buscador</h3>
                <v-img
                  lazy-src="@/assets/img/notificaciones-buscador.png"
                  src="@/assets/img/notificaciones-buscador.png"
                  class="mb-5"
                  max-height="350"
                  max-width="100%"   
                  contain
                ></v-img>
              </v-col>
              <v-col class="text-center">
                <h3>Instituciones propuestas/aprobadas</h3>
                <v-img
                  lazy-src="@/assets/img/notificaciones-tabla.png"
                  src="@/assets/img/notificaciones-tabla.png"
                  class="mb-5"
                  max-height="300"
                  max-width="100%"   
                  contain
                ></v-img>
              </v-col>
            </v-row>

            <p class="font-weight-medium black--text mt-3">Códigos de color</p>

            <v-row>
              <v-col md="7">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="80" class="text-left">Color</th>
                        <th class="text-left">Significado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><v-icon color="grey lighten-1">mdi-comment-alert-outline</v-icon></td>
                        <td>La institución  no tiene notificaciones.</td>
                      </tr>
                      <tr>
                        <td><v-icon color="primary">mdi-comment-alert-outline</v-icon></td>
                        <td>La institución tiene notificaciones y han sido revisadas por el administrador.</td>
                      </tr>
                      <tr>
                        <td><v-icon color="red">mdi-comment-alert-outline</v-icon></td>
                        <td>La institución tiene notificaciones y NO han sido revisadas por el administrador.</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>          

          </v-card-text>
        </v-card>
      </v-col>

      <v-col id="roles-usuarios" md="8">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Roles de usuario y permisos
          </v-card-title>
          <v-card-text>
            <p class="font-weight-medium black--text">Existen 3 tipos de usuarios:</p>
            <ul>
              <li>Suscriptores</li>
              <li>Editores</li>
              <li>Administradores</li>
            </ul>

            <p class="mt-4">Cada grupo tiene asociados unos <span class="font-weight-medium black--text">derechos de lectura, escritura y borrado para cada recurso</span>. A continuación se explican más en detalle:</p>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Tipo de usuario</th>
                    <th class="text-left">Buscador</th>
                    <th class="text-left">Instituciones propuestas</th>
                    <th class="text-left">Instituciones aprobadas</th>
                    <th class="text-left">Usuarios</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Suscriptores</th>
                    <td>
                      <ul>
                        <li>buscar instituciones</li>
                        <li>leer notificaciones</li>
                      </ul>  
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th>Editores</th>
                    <td>
                      <ul>
                        <li>buscar instituciones</li>
                        <li>leer notificaciones</li>
                        <li>añadir notificaciones</li>
                      </ul>  
                    </td>
                    <td>
                      <ul>
                        <li>proponer</li>
                        <li>editar</li>
                        <li>leer notificaciones</li>
                        <li>añadir notificaciones</li>
                      </ul>
                    </td>
                    <td>-</td>
                    <td>-</td>                   
                  </tr>
                  <tr>
                    <th>Administradores</th>
                    <td>
                      <ul>
                        <li>buscar instituciones</li>
                        <li>leer notificaciones</li>
                        <li>añadir notificaciones</li>
                      </ul>  
                    </td>
                    <td>
                      <ul>
                        <li>proponer</li>
                        <li>editar</li>
                        <li>aprobar</li>
                        <li>borrar</li>                        
                        <li>leer notificaciones</li>
                        <li>añadir notificaciones</li>
                        <li>desactivar alertas</li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>editar</li>
                        <li>borrar</li> 
                        <li>leer notificaciones</li>
                        <li>añadir notificaciones</li>
                        <li>desactivar alertas</li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>añadir</li>
                        <li>editar</li>
                        <li>borrar</li> 
                      </ul>
                    </td>    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col id="gestion-usuarios" md="8">
        <v-card>
          <v-card-title :class="cardTitleClasses">
            Gestión de usuarios
          </v-card-title>
          <v-card-text>
            <p><span class="font-weight-medium black--text">Añadir usuario</span>: Al añadir un usuario se le envía un correo de bienvenida con el nombre de usuario y contraseña desde la cuenta sin buzón de timii@pohlbros.com.</p>
            <p><span class="font-weight-medium black--text">Editar usuario</span>: Al editar un usuario se pueden dar 2 casos: si no se asigna una nueva contraseña sólo se actualizan los datos de usuario. Si se asigna una nueva contraseña se vuelve a enviar el correo de bienvenida con con el nombre de usuario y contraseña.</p>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'Help',
    data: () => ({
      topics: [
        {
          name: '· Objetivo',
          tag: '#objetivo'
        },
        {
          name: '· Instrucciones para el uso tags',
          tag: '#instrucciones-tags'
        },
        {
          name: '· Indicaciones para la inserción y actualización de datos',
          tag: '#insercion-actualizacion-datos'
        },
        {
          name: '· Uso de notificaciones',
          tag: '#notificaciones'
        },
        {
          name: '· Roles de usuario y sus permisos',
          tag: '#roles-usuarios'
        },
        {
          name: '· Gestión de usuarios',
          tag: '#gestion-usuarios'
        }
      ],
      cardTitleClasses: 'grey lighten-3 py-3 mb-4'
    }),
    mounted() {},
    computed: {},
    methods: {},
  }
</script>
<style scoped>
  .v-list-item {
    min-height: 30px !important;
  }
  .v-list-item__content{
    padding: 0 0;
  }
  td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    vertical-align: top;
  }
</style>